'use strict'

const React = require('react')
const PropTypes = require('prop-types')
const _ = require('lodash')

class WixImageReact extends React.Component {
    constructor(props) { // eslint-disable-line no-useless-constructor
        super(props)
    }

    render() {
        return React.createElement('wix-image', {
            id: `${this.props.id}Element`,
            style: this.props.style,
            'data-style': this.props['data-style'],
            'data-image-info': JSON.stringify(
                _.pick(this.props, ['imageData', 'alignType', 'displayMode', 'containerId'])
            )}, this.props.images
        )
    }
}

WixImageReact.displayName = 'WixImageReact'
WixImageReact.propTypes = {
    id: PropTypes.string,
    images: PropTypes.object,
    imageData: PropTypes.object.isRequired,
    alignType: PropTypes.string,
    displayMode: PropTypes.string,
    containerId: PropTypes.string,
    style: PropTypes.object,
    'data-style': PropTypes.string
}

module.exports = WixImageReact